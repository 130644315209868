import AppConfig from '../../../appConfig';
import http from '../../http';
import { GlobalNavData } from '../../../types/GlobalNavData';
import {
  generateEducationAndCertificationData,
  generateAppID,
  getHelpDocUrl,
  generateBoardsData,
  removeRestrictedApps,
  removeBoardXPRestrictedApps,
  installersData,
  getboardEntitledOrgs,
} from '../../../helpers/common';
import boardsCustomerApps from '../../../appConfig/boardsCustomerApps/boardsCustomerApps';

export default async function getGlobalNavData(org_id?: string, user_id?: string): Promise<any | undefined> {
  const response = await http.get(getGlobalNavBarApiUrl(org_id, user_id));
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(response.statusText);
  }
}

export function transformation(data: any, org_id: string | undefined): GlobalNavData {
  let transformedData = undefined;
  if (AppConfig.featureToggles.useNewGlobalNavBarApi) {
    transformedData = transformGlobalNavData(data);
    //add education, certification and boards data to new navbar apps
    if (AppConfig.featureToggles.useBoardSectionTabs && getboardEntitledOrgs().includes(parseInt(org_id || '')))
      transformedData.data.appSwitcherProps.apps = boardsCustomerApps;
    let appData = transformedData.data.appSwitcherProps.apps;
    let appId = generateAppID(appData);
    //prevent unnecessary data duplication as transformation function is called multiple times during render
    const transformationCompletedFlag = appData.some((app: any) => app.key === 'diligent-institute');
    if (!transformationCompletedFlag) {
      appData.push(
        ...generateEducationAndCertificationData(appId),
        ...generateBoardsData(appId, org_id),
        ...installersData(appId, transformedData.data.links.downloads),
      );
    }
    //remove compliance maps from new navbar apps if boardEffect app is present (temporary fix)
    return removeBoardXPRestrictedApps(removeRestrictedApps(transformedData), org_id);
  }
  //modify the app key convention of old navbar data
  const modifiedAppData = modifyAppKeyConvention(data);
  //add education, certification and boards data to old navbar apps
  let appId = generateAppID(modifiedAppData.data.appSwitcherProps.apps);
  modifiedAppData.data.appSwitcherProps.apps.push(
    ...generateEducationAndCertificationData(appId),
    ...generateBoardsData(appId, org_id),
    ...installersData(appId, modifiedAppData.data.links.downloads),
  );
  //remove compliance maps from new navbar apps if boardEffect app is present (temporary fix)
  return removeBoardXPRestrictedApps(removeRestrictedApps(modifiedAppData), org_id);
}

function modifyAppKeyConvention(inputData: any): GlobalNavData {
  const apps = inputData.data.appSwitcherProps.apps.map((app: any) => {
    return { ...app, key: app.key.replace(/_/g, '-') };
  });
  return {
    ...inputData,
    data: { ...inputData.data, appSwitcherProps: { ...inputData.data.appSwitcherProps, apps: apps } },
  };
}

function transformGlobalNavData(inputData: any): GlobalNavData {
  const transformedData: GlobalNavData = {
    data: {
      appSwitcherProps: {
        resourceLinks: {
          academy: inputData.data.links.academy,
          community: inputData.data.links.community,
          toolkits: inputData.data.links.toolkits,
          help_docs: getHelpDocUrl(),
          support: inputData.data.links.support,
          downloads: inputData.data.links.downloads,
        },
        apps: inputData.data.apps,

        organizations: inputData.data.organizations,
        links: inputData.data.links,
      },
      links: inputData.data.links,
      userFullName: inputData.data.user.userFullName,
      messages: inputData.data?.messages,
    },
  };

  return transformedData;
}

function getGlobalNavBarApiUrl(org_id: string | undefined, user_id: string | undefined): string {
  if (AppConfig.featureToggles.useNewGlobalNavBarApi) {
    return `${AppConfig.accountsApi}/api/v1/orgs/${org_id}/users/${user_id}/global-nav-bar`;
  } else {
    return `${AppConfig.publicApi}/accounts/api/global_nav_bar?org_id=${org_id}`;
  }
}
